// extracted by mini-css-extract-plugin
export var bChildren = "privacy-module--bChildren--D2vew";
export var buttonContainer = "privacy-module--buttonContainer--tW7HQ";
export var buttonPrivacy = "privacy-module--buttonPrivacy--FCiQg";
export var fotterPrivacy = "privacy-module--fotterPrivacy--5FnBe";
export var privacyBackground = "privacy-module--privacyBackground--ic2NP";
export var privacyContainer = "privacy-module--privacyContainer--7Kveg";
export var sectionChildren = "privacy-module--sectionChildren---QBDo";
export var subtitle = "privacy-module--subtitle--G0twJ";
export var title = "privacy-module--title--C6GLG";
export var update = "privacy-module--update--q-jeV";