import React from 'react';
import Seo from '../components/Seo/Seo';
import Container from '../components/Container/Container';
import * as classes from './privacy.module.css';
import Scroll from '../components/Scroll/Scroll';
import { Link } from 'gatsby';

import app from '../firebase/firebase';
import { getAnalytics, logEvent } from "firebase/analytics";

const Privacy = () => {

  React.useEffect(() => {
    if (!app) {
      return
    }

    const analytics = getAnalytics(app);
    logEvent(analytics, 'visited_private');
  }, [])

  return (
    <div className={classes.privacyBackground}>
      <Scroll />
      <Container>
        <Seo title={`Privacy Policy`} />
        <div className={classes.privacyContainer}>
          <span className={classes.title}>Privacy Policy</span>
          <span className={classes.update}>
            Updated October, 2022.
          </span>
          <section style={{ marginTop: "2.2rem" }}>
            <span className={classes.subtitle}>Overview</span>
            <p>
              kChat is a messaging app for children that helps them communicate with family and friends in a fun and age-appropriate environment. kChat is designed to give you control over how and when your child shares data and who they can communicate with. It also gives you insights into how your child uses the app, including access to the information your child shares and receives. Please review this Privacy Policy together with your child to ensure you both understand what information we collect when your child uses kChat and how we use and share that information.
            </p>
          </section>
          <section>
            <span className={classes.subtitle}>What kinds of information do we collect?:</span>
            <p>
              We collect the following information from children and their parents or guardians when they sign up for and use kChat.
              <ul>
                <li>kChat Account Details: Depending on the features you and your child use, we collect the following information:</li>
                <ul>
                  <li>Your child’s full name;</li>
                  <li>Your child’s profile picture;</li>
                  <li>We do NOT store or share your contacts, they are only accessed ephemerally to help parents or guardians to connect with other kChat users;</li>
                </ul>
                <li>Use of kChat: We collect the following types of information about your child’s use of kChat:</li>
                <ul>
                  <li>Content and Communications: We collect the content and type of messages, including stickers, gifs, photos, or videos, your child shares from both sent and received messages in kChat. These may include your child’s image or voice if provided. </li>
                  <li>Usage: We collect anonimized information about how your child uses kChat, such as the features they use and how they engage with the app.</li>
                </ul>
                <li>Device Information: We collect generic  information about the phone or device your child uses to access kChat. This information includes:</li>
                <ul>
                  <li>Device attributes: information like the operating system, hardware and software versions. </li>
                  <li>Data from device settings: information provided through device settings you or your child turn on, like access to your camera, photos, or microphone.</li>
                </ul>
              </ul>
            </p>
          </section>
          <section>
            <span className={classes.subtitle}>GDPR/COPPA compliance</span>
            <p>
              kChat is COPPA and GDPR compliant. The product was built based on this act and other “for kids” guidelines such as Apple Store, Play Store among others.
            </p>
          </section>
          <section>
            <span className={classes.subtitle}>How do we use this information?</span>
            <p>
              <ul>
                <li>Enable your child to communicate: We use the information we have about you and your child to enable them to connect and communicate with others.</li>
                <li>Personalize your child’s kChat experience: We use the information we have to optimize kChat for your child and others, including personalization of product features.</li>
                <li>Enhance our services: We use the information we have to evaluate, troubleshoot, improve, create, and develop our products and to help make kChat a safer, more secure and more fun place for children.</li>
                <li>Advance safety and security on and off kChat: We use the information we have to improve safety for our users on and off the product. This includes investigating suspicious activity, reported content, violations of our terms or policies, preventing spam and other bad experiences, combating harmful conduct, verifying accounts and activities, and maintaining the integrity of kChat. We may accomplish this through human review, automated systems, and/or machine learning.</li>
              </ul>
            </p>
          </section>
          <section>
            <span className={classes.subtitle}>How is this information shared?</span>
            <p>
              <ul>
                <li>Your Child’s Contacts.</li>
                <ul>
                  <li>You have control over who can communicate with your child through kChat.. You enable your child to join group conversations with kChat users who are not individually approved by adding them to a group.</li>
                  <li>Your child’s name, profile picture is visible to other kchat users your kid is allowed to interact with.</li>
                  <li>The people your child communicates with see the content your child shares with them.</li>
                </ul>
                <li>Parents and Guardians.</li>
                <ul>
                  <li>Through the Parent Dashboard, you, as the parent or guardian can view video and photo messages your child sends or receives on kChat by accessing his profile. You can also review your child’s account details and account activity. Remember, parents or guardians of other kChat users can always see the information your child shares with their child.</li>
                </ul>
                <li>Other children, parents or guardians:</li>
                <ul>
                  <li>Other parents who manage a kChat account and people you invite to create a kChat account can see your child’s name, profile picture, and relationship to you and any other managing parents or guardians.</li>
                </ul>
                <li>Our vendors and service providers. We may transfer information we collect to service providers that are needed to support kChat, such as companies that provide technical infrastructure and support (like a content delivery network) or customer service, or that analyze how kChat is being used to help us improve the service. These partners must adhere to strict data confidentiality and security obligations, such as restrictions on how they can use the data. We don’t sell any of your or your child’s information to anyone and we never will.</li>
                <li>New Owners. If the ownership or control of all or part of kChat changes, we may transfer information to the new owner.</li>
                <li>Law enforcement or legal requests. We share information with law enforcement or in response to legal requests in the circumstances outlined below.</li>
              </ul>
            </p>
          </section>
          <section>
            <span className={classes.subtitle}>How can parents access, manage, and delete information about their child?</span>
            <p>
              You can manage and review information about your child in the Parent Dashboard, which is located under the app “settings” menu. This Parent Dashboard allows you to make changes to your child’s kChat profile, remove contacts, manage groups and change permissions you have opted into. In addition, you can see your child’s messages on kChat and their account information by accessing their profile with the parent pincode if needed.
            </p>
            <p>
              To stop kChat from collecting and using your child’s personal information on kChat, you can delete your child’s kChat Account through the app settings. If you delete your child’s account, we will delete their kChat profile information, information about their activity and contacts, device information, and their messages.
            </p>
            <p>
              We store information until it is no longer necessary to provide our services, or until your child’s account is deleted, whichever comes first. This is a case-by-case determination that depends on things like the nature of the data, why it is collected and processed, and relevant legal or operational retention needs. For example, we may retain registration information subject to law enforcement requests in order to help make the kChat community safer.
            </p>
          </section>
          <section>
            <span className={classes.subtitle}>Reporting Suspicious Activity</span>
            <p>
              As described above, we use the information we have to promote children’s safety on kChat, but we also provide a reporting tool for them to use when needed. Through their kChat account, your child can report inappropriate content (including harassing, bullying, sexual or violent content) or suspicious activity (for example, if they think someone else is impersonating their friend or family member) and can immediately block the offending individual on kChat. If your child reports someone, we will notify you that they did so.
            </p>
          </section>
          <section>
            <span className={classes.subtitle}>How do we respond to legal requests or prevent harm?</span>
            <p>
            We access, preserve and share your child's information with regulators, law enforcement or others:
            </p>
            <p>
            <ul>
              <li>In response to a legal request, if we have a good-faith belief that the law requires us to do so. We can also respond to legal requests when we have a good-faith belief that the response is required by law in that jurisdiction, affects users in that jurisdiction, and is consistent with internationally recognized standards.</li>
              <li>When we have a good-faith belief it is necessary to: detect, prevent and address fraud, unauthorized use of kChat, violations of our terms or policies, or other harmful or illegal activity; to protect ourselves (including our rights, property or products), you or others, including as part of investigations or regulatory inquiries; or to prevent death or imminent bodily harm.</li>
            </ul>
            </p>
            <p>
            Information we receive about you or your child can be accessed and preserved for an extended period when it is the subject of a legal request or obligation, governmental investigation, or investigations of possible violations of our terms or policies, or otherwise to prevent harm. We also retain information from accounts that have been disabled for terms violations for at least a year to prevent repeat abuse or other term violations.
            </p>
          </section>
          <section>
            <span className={classes.subtitle}>How do we operate and transfer data as part of our global services?</span>
            <p>
            Your information may, for example, be transferred or transmitted to, or stored and processed in the United States or other countries outside of where you live for the purposes as described in this policy. These data transfers are necessary to provide the services set forth in the kChat Terms and to globally operate and provide our product to you. We utilize standard contract clauses, rely on the European Commission's adequacy decisions about certain countries, as applicable, and obtain your consent for these data transfers to the United States and other countries.
            </p>
          </section>
          <section>
            <span className={classes.subtitle}>Updates and Changes to this Policy</span>
            <p>
            We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the Privacy Policy, and in some cases (such as for material changes), we will provide you with additional notice (such as adding a statement to our web site’s homepage or sending you a notification) and obtain your prior verifiable consent (where required).
            </p>
          </section>
          <section>
            <span className={classes.subtitle}>Contacting kChat</span>
            <p>
            If you have any comments, questions, concerns or complaints regarding your personal information or our privacy practices you can contact us at: <a href='mailto:privacy@kchat.fun'>privacy@kchat.fun</a>.
            </p>
          </section>
          <div className={classes.buttonContainer}>
            <Link to={`/`} className={classes.buttonPrivacy}>I Agree</Link>
          </div>
        </div>
      </Container>
      <footer className={classes.fotterPrivacy}>
      </footer>
    </div>
  )
}

export default Privacy